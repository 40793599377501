/* Add this CSS to your stylesheets or component styles */

.promo-code-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.news-station-text {
  display: inline-block;
  white-space: nowrap;
  animation: scrollText 40s linear infinite; /* Adjust the duration as needed */
}

@keyframes scrollText {
  from {
    transform: translateX(70%);
  }
  to {
    transform: translateX(-10%);
  }
}
