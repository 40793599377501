#producer_content #producer_image h2 {
  color: #fff;
}

#producer_content #producer_image h3 {
  color: #fff;
}

#producer_content #producer_image p {
  color: #fff;
}

#h1Producer {
  font-size: 2.5em;
  margin-bottom: 0.5em;
  color: #333;
  font-weight: 600;
  line-height: 1.2;
  border-bottom: 1px solid #eaecef;
  padding-bottom: 0.3em;
  margin-top: 1.5em;
}

.h2Producer {
  font-size: 2em;
  margin-bottom: 0.5em;
  color: #333;
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: 0.3em;
  margin-top: 1.5em;
}

#producer_content h3 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
  color: #333;
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: 0.3em;
  margin-top: 1.5em;
}

#producer_content p {
  margin-bottom: 1.5em;
  font-size: 1.2em;
  line-height: 1.6;
  color: #333;
  text-align: justify;
}

#producer_content a {
  color: #0366d6;
  text-decoration: none;
  border-bottom: 1px solid #0366d6;
}

#producer_content a:hover {
  color: #0056b3;
  border-bottom-color: #0056b3;
}

#producer_content ul {
  margin-bottom: 1.5em;
  padding-left: 2em;
  list-style-type: disc;
}

#producer_content ol {
  margin-bottom: 1.5em;
  padding-left: 2em;
  list-style-type: decimal;
}

#producer_content li {
  margin-bottom: 0.5em;
  font-size: 1.2em;
  line-height: 1.6;
  color: #333;
}

#producer_content blockquote {
  margin-bottom: 1.5em;
  padding: 0.5em 1em;
  border-left: 0.25em solid #dfe2e5;
  background-color: #f6f8fa;
  color: #6a737d;
}

#producer_content pre {
  margin-bottom: 1.5em;
  padding: 1em;
  background-color: #f6f8fa;
  border-radius: 3px;
  overflow: auto;
  font-size: 0.9em;
  line-height: 1.45;
  color: #333;
}

#producer_content code {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 3px;
}

#producer_content img {
  max-width: 100%;
  margin-bottom: 1.5em;
}

#producer_content table {
  margin-bottom: 1.5em;
  border-collapse: collapse;
  width: 100%;
}

#producer_content th {
  padding: 0.5em 1em;
  border: 1px solid #dfe2e5;
  background-color: #f6f8fa;
  color: #24292e;
}

#producer_content td {
  padding: 0.5em 1em;
  border: 1px solid #dfe2e5;
}

#producer_content hr {
  margin-bottom: 1.5em;
  border: 0;
  border-top: 1px solid #dfe2e5;
}

#producer_content .highlight {
  margin-bottom: 1.5em;
  padding: 1em;
  background-color: #f6f8fa;
  border-radius: 3px;
  overflow: auto;
  font-size: 0.9em;
  line-height: 1.45;
  color: #333;
}

#producer_content .highlight pre {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 0;
  font-size: 1em;
  line-height: 1.6;
  color: #333;
}

@media (max-width: 768px) {
  #producer_content h1 {
    font-size: 2em;
  }

  #producer_content h2 {
    font-size: 1.5em;
  }

  #producer_content h3 {
    font-size: 1.25em;
  }

  #producer_content p {
    font-size: 1em;
  }

  #producer_content a {
    font-size: 1em;
  }

  #producer_content ul {
    font-size: 1em;
  }

  #producer_content ol {
    font-size: 1em;
  }

  #producer_content li {
    font-size: 1em;
  }

  #producer_content blockquote {
    font-size: 1em;
  }

  #producer_content pre {
    font-size: 0.8em;
  }

  #producer_content code {
    font-size: 0.8em;
  }

  #producer_content img {
    margin-bottom: 1em;
  }

  #producer_content table {
    font-size: 1em;
  }

  #producer_content th {
    font-size: 1em;
  }

  #producer_content td {
    font-size: 1em;
  }

  #producer_content hr {
    margin-bottom: 1em;
  }

  #producer_content .highlight {
    font-size: 0.8em;
  }

  #producer_content .highlight pre {
    font-size: 0.8em;
  }
}

@media (max-width: 576px) {
  #producer_content h1 {
    font-size: 1.75em;
  }

  #producer_content h2 {
    font-size: 1.25em;
  }

  #producer_content h3 {
    font-size: 1em;
  }

  #producer_content p {
    font-size: 0.9em;
  }

  #producer_content a {
    font-size: 0.9em;
  }

  #producer_content ul {
    font-size: 0.9em;
  }

  #producer_content ol {
    font-size: 0.9em;
  }

  #producer_content li {
    font-size: 0.9em;
  }

  #producer_content blockquote {
    font-size: 0.9em;
  }

  #producer_content pre {
    font-size: 0.7em;
  }

  #producer_content code {
    font-size: 0.7em;
  }

  #producer_content img {
    margin-bottom: 0.5em;
  }

  #producer_content table {
    font-size: 0.9em;
  }

  #producer_content th {
    font-size: 0.9em;
  }

  #producer_content td {
    font-size: 0.9em;
  }

  #producer_content hr {
    margin-bottom: 0.5em;
  }

  #producer_content .highlight {
    font-size: 0.7em;
  }

  #producer_content .highlight pre {
    font-size: 0.7em;
  }
}
